import React from 'react'
import '../../../styles/AboutIntro.css'
import { Link } from 'react-router-dom'
import BannerImgs from '../BannerImgs/BannerImgs'

function AboutIntro() {
    return (
        <div className='AboutIntro'>
            <div className="container">
                <div className="row">
                    <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000" >
                        <h2><span>Whitefield Locks:</span> Elevate Your Security Standard, Illuminate Your Entryways</h2>
                        <p>Welcome to Whitefield Locks, where we redefine home security with innovative and elegant door lock solutions. Our cutting-edge technology safeguards your sanctuary with precision and style. Going beyond conventional security, our locks prioritize safety and aesthetic appeal. As industry pioneers, we pride ourselves on delivering excellence, ensuring your entryways provide both security and the sophistication of modern living. Explore the quality and reliability that Whitefield Locks brings, elevating security standards for a tranquil and protected home.</p>
                        <div className="button">
                            <Link to="/about"><span>Read More</span></Link>
                        </div>
                    </div>
                    <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000" >
                        <img src="img/herosection/herosection-4.jpg" alt="" />

                    </div>
                </div>
                <BannerImgs />
            </div>
        </div>
    )
}

export default AboutIntro